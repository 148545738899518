<template>
<div class="top-box" v-if="id">
    <span class="inline"></span>
    <span class="text">编辑试卷</span>
</div>
  <a-form
    class="myForm box-shadow"
    :label-col="{ span: 2 }"
    :wrapper-col="{ span: 21 }"
    :model="formState"
    :rules="rules"
    ref="formRef"
    onkeydown="if(event.keyCode==13)return false">
      <a-form-item label="试卷名" name="name">
        <a-input placeholder="请输入试卷名" autocomplete="off" v-model:value="formState.name" :maxlength="30"></a-input>
      </a-form-item>
      <a-form-item label="试卷标签" name="tag_id">
        <initSelect v-model:value="formState.tag_id" :list="examTagList" placeholder="请选择习题标签"></initSelect>
      </a-form-item>
      <a-form-item label="考试时间" name="exam_time">
        <a-input-number :min="30" :max="150" :step="10" v-model:value="formState.exam_time"/>分钟
      </a-form-item>
      <a-form-item label="备注">
        <a-input placeholder="请输入备注" v-model:value="formState.remarks"></a-input>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 3, offset: 2 }">
        <a-button
        v-if="!id"
          type="primary"
          htmlType="submit"
          @click.prevent="fetchCreate"
        >
          提交
        </a-button>
        <a-button
          v-else
          type="primary"
          htmlType="submit"
          @click.prevent="fetchEdit"
        >
          保存
        </a-button>
      </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import initSelect from '@/components/select.vue'
import { EXERISE_TAG } from '@/hooks/config'
import { createExam, editExamCom, fetchExamDetail, examTag } from '@/api/index'
import { message } from 'ant-design-vue'
import { filterKeys } from '@/hooks/common-hooks'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'createExam',
  props: ['id'],
  components: {
    initSelect
  },
  setup (props, ctx) {
    const router = useRouter()
    // 表单验证
    const rules = {
      name: [{ required: true, trigger: 'blur', message: '请输入试卷名', whitespace: true }],
      exam_time: [{ type: 'number', required: true, trigger: 'blur', message: '请输入考试时间', whitespace: true }],
      tag_id: [{ required: true, message: '请选择试卷标签', type: 'number' }]
    }
    const formState = reactive({
      tag_id: null,
      name: '',
      exam_time: 30,
      remarks: ''
    })
    const formRef = ref()
    // 创建试卷
    const fetchCreate = () => {
      formRef.value.validate().then(() => {
        createExam(formState).then(() => {
          message.success('创建成功')
          ctx.emit('changeIndex', 0)
          formRef.value.resetFields()
        })
      })
    }
    // 编辑试卷基础信息
    const fetchEdit = () => {
      formRef.value.validate().then(() => {
        editExamCom(Object.assign(formState, { id: props.id })).then(() => {
          message.success('编辑成功')
          router.push({
            name: 'ExamManager'
          })
        })
      })
    }
    const examTagList = ref([])
    const getExamTag = () => {
      examTag({}).then(res => {
        examTagList.value = res.data
      })
    }
    onMounted(() => {
      getExamTag()
      if (!props.id) return
      fetchExamDetail({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
      })
    })
    return {
      examTagList,
      rules,
      EXERISE_TAG,
      formState,
      formRef,
      fetchCreate,
      fetchEdit
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/scss/form.scss';
</style>
