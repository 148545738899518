<template>
  <div>
    <a-select
      :mode="mode"
      class="myselects"
      :value="value"
      :placeholder="placeholder"
      @change="changeTag"
      :allowClear="allowClear"
    >
      <a-select-option v-for="item in list" :value="item.id" :key="item.id" :label="item.name ? item.name : item.item">{{item.name ? item.name : item.item}}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    value: {},
    list: {},
    placeholder: {
      type: String,
      default: '请选择'
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String
    }
  },
  setup (props, ctx) {
    const changeTag = (value) => {
      ctx.emit('update:value', value)
      ctx.emit('change')
    }
    return {
      changeTag
    }
  }
})
</script>

<style scoped>
.ant-select.myselects{
  width: 100%;
}
</style>
